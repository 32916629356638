import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const Tout = makeShortcode("Tout");
const Chocolate = makeShortcode("Chocolate");
const Icon = makeShortcode("Icon");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Collateral and Risk`}</h1>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/illustrations/Tall-1.png",
          "alt": "Collateral and Risk"
        }}></img></p>
      <Box mdxType="Box">
        <h2>{`Learn about risks to the Dai Credit System`}</h2>
      </Box>
    </InfoBlock>
    <p>{`MakerDAO is an Ethereum-based DAO that operates the Dai Credit System, a permissionless loan facility.`}</p>
    <p>{`The Dai Credit System issues the cryptocurrency Dai, a `}<a parentName="p" {...{
        "href": "/learn/blockchain-fundamentals/stablecoins.mdx/"
      }}>{`stablecoin`}</a>{` designed to track $1 USD with low volatility. Dai's stability dependends on a system of carefully crafted incentives. `}</p>
    <p>{`Collateral can be retrieved upon repayment of the outstanding Dai amount plus an associated Stability Fee. `}</p>
    <p>{`This architecture poses two pressing concerns: `}</p>
    <p><strong parentName="p">{`1. Where does Dai source its value?`}</strong></p>
    <p><strong parentName="p">{`2. What compels it to remain pegged to USD?`}</strong></p>
    <p>{`The answers to these two questions are the focal points of most risk analysis surrounding Dai.`}</p>
    <Tout mdxType="Tout">
      <Box mdxType="Box">
        <Chocolate mdxType="Chocolate">
          <Box mdxType="Box">
            <Icon size={"40px"} name="dai" mdxType="Icon" />
            <h3>{`Dai Credit System`}</h3>
            <p><a parentName="p" {...{
                "href": "/collateral-and-risk/dai-credit-system.mdx"
              }}>{`Dai Credit System`}</a></p>
          </Box>
          <Box mdxType="Box">
            <Icon size={"40px"} name="wrench" mdxType="Icon" />
            <h3>{`Types of Risk`}</h3>
            <p><a parentName="p" {...{
                "href": "/collateral-and-risk/types-of-risk.mdx/"
              }}>{`Types of Risk`}</a></p>
          </Box>
          <Box mdxType="Box">
            <Icon size={"40px"} name="people" mdxType="Icon" />
            <h3>{`Risk Teams`}</h3>
            <p><a parentName="p" {...{
                "href": "/collateral-and-risk/risk-teams.mdx"
              }}>{`Risk Teams`}</a></p>
          </Box>
        </Chocolate>
      </Box>
    </Tout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      